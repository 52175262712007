import React, { useState } from 'react';
import { MDBInput, MDBCheckbox, MDBBtn } from 'mdb-react-ui-kit';

export default function App() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [agree, setAgree] = useState(true); // Valor inicial de acuerdo

    const handleCheckboxChange = () => {
        setAgree(!agree);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = {
            name: name,
            email: email,
            agree: agree,
        };

        try {
            const response = await fetch('URL_DEL_SERVIDOR', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Datos enviados correctamente');
                // Puedes realizar acciones adicionales aquí después de enviar los datos
            } else {
                console.error('Error al enviar datos');
            }
        } catch (error) {
            console.error('Error de red:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <MDBInput
                className='mb-4'
                id='form5Example1'
                label='Name'
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <MDBInput
                className='mb-4'
                type='email'
                id='form5Example2'
                label='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <MDBCheckbox
                wrapperClass='d-flex justify-content-center mb-4'
                id='form5Example3'
                label='I have read and agree to the terms'
                checked={agree}
                onChange={handleCheckboxChange}
            />

            <MDBBtn type='submit' block>
                Subscribe
            </MDBBtn>
        </form>
    );
}
