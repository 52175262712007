// App.js
import React from 'react';
import LoginView from '../../components/forms/login';

function App() {
    return (
        <div>
            <LoginView />
        </div>
    );
}

export default App;
