// service-worker.js

import { precacheAndRoute } from 'workbox-precaching';
import { registerRoute } from 'workbox-routing';
import { NetworkFirst } from 'workbox-strategies';

// Precaching y enrutamiento
precacheAndRoute(self.__WB_MANIFEST || []);

// Enrutamiento personalizado (puedes agregar más rutas según tus necesidades)
registerRoute(
    ({ request }) => request.destination === 'image',
    new NetworkFirst()
);

// Puedes agregar más estrategias y enrutamientos según tus necesidades

self.addEventListener('install', (event) => {
    self.skipWaiting();
});

self.addEventListener('activate', (event) => {
    event.waitUntil(self.clients.claim());
});
// Exporta la función de registro
export const register = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const pathToServiceWorker = './service-worker.js'; // Ajusta la ruta según la ubicación de tu aplicación
            const registration = await navigator.serviceWorker.register(pathToServiceWorker);
            console.log('Service Worker registrado con éxito:', registration);
        } catch (error) {
            console.error('Error al registrar el Service Worker:', error);
        }
    }
};
