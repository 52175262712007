import React from 'react';

import LoginView from "./views/login/login";

function App() {
  return (
      <div className="container d-flex justify-content-center align-items-center vh-100">
        <LoginView />
      </div>
  );
}

export default App;
